<template>
  <div>
    <q-form ref="editForm">
      <c-card title="SOP 부가정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-12 col-md-2 col-lg-2">
            <c-select
              codeGroupCd="HAZARD_GRADE_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="hazardGradeCd"
              label="위험등급"
              v-model="sop.hazardGradeCd"
            ></c-select>
          </div>
          <div class="col-xs-6 col-sm-12 col-md-2 col-lg-2">
            <c-field
              :editable="editable"
              label="작업자"
              name="workerName"
              v-model="sop.workerName">
            </c-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <c-text
              :editable="editable"
              label="안전보호구"
              name="saftyShied"
              v-model="sop.saftyShied">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :rows="3"
              label="적용범위"
              name="applyRange"
              v-model="sop.applyRange">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-textarea
              :editable="editable"
              :rows="3"
              label="목적"
              name="purpose"
              v-model="sop.purpose">
            </c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <c-textarea
              :counter="true"
              :maxlength="200"
              :editable="editable"
              :rows="3"
              label="작업시 안전주의사항"
              name="saftyCaution"
              v-model="sop.saftyCaution">
            </c-textarea>
          </div>
        </template>
      </c-card>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 margincard-0">
          <c-table
            ref="mmEquipTable"
            title="관련 설비"
            tableId="mmEquipTable"
            :columns="gridMmEquip.columns"
            :data="sop.mmEquips"
            gridHeight="284px"
            selection="multiple"
            rowKey="mdmSopMmCd"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :isExcelDown="false"
            :hideBottom="true"
            topBorderClass="topcolor-blue"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && isOld"
                  label="LBLADD"
                  icon="add"
                  @btnClicked="addRowMmEquip"
                />
                <c-btn
                  v-if="editable && isOld"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="removeRowMmEquip"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 margincard-0">
          <c-table
            ref="mmMachineTable"
            title="관련 유해위험기계기구"
            tableId="mmMachineTable"
            :columns="gridMmMacine.columns"
            :data="sop.mmMachines"
            gridHeight="284px"
            selection="multiple"
            rowKey="mdmSopMmCd"
            :isFullScreen="false"
            :columnSetting="false"
            :filtering="false"
            :usePaging="false"
            :isExcelDown="false"
            :hideBottom="true"
            topBorderClass="topcolor-blue"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && isOld"
                  label="LBLADD"
                  icon="add"
                  @btnClicked="addRowMmMachine"
                />
                <c-btn
                  v-if="editable && isOld"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="removeRowMmMachine"
                />
              </q-btn-group>
            </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'sopAdditionalInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmSopId: '',
      }),
    },
    sop: {
      type: Object,
      default: () => ({
        mdmSopId: '',  // 안전작업 표준 일련번호
        plantCd: null,  // 사업장코드
        subProcessCd: '',  // 단위공정 코드
        sopNo: '',  // 안전작업 표준 번호
        sopName: '',  // 세부공정(작업) 명
        revNo: '',  // 개정번호
        revReason: '',  // 개정사유
        applyRange: '',  // 적용범위
        purpose: '',  // 목적
        hazardGradeCd: null,  // 위험등급 1 ~ 5등급
        workTypeCd: null,  // 작업유형 : 특별안전, 일반안전, 단순일상
        workKindCd: null,  // 작업형태 : Gas, 고소, 굴착/매설, 방사선, 전기, 지게차, 기타
        workClassCd: null,  // 작업구분 : Set-up, PM, BM, Part교체, 기타
        workerName: '',  // 작업자
        managementDepts: '',  // 관리부서 복수
        managementDepts2: '',  // 관리부서 복수
        saftyShied: '',  // 안전보호구
        saftyCaution: '',  // 안전주의사항
        useFlag: 'Y',  // 사용여부
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        regUserId: '',
        chgUserId: '',
        revSelectValue: null,
        sopSteps: [], // 작업단계 목록
        revs: [], // 개정 목록
        mmEquips: [], // 설비 목록
        mmMachines: [], // 기계기구 목록
        chems: [], // 화학자재 목록
        sopSummary: [], // 요약정보
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      gridMmMacine: {
        columns: [
          {
            name: 'equipMachineCd',
            field: 'equipMachineCd',
            label: '기계기구 번호',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipMachineName',
            field: 'equipMachineName',
            label: '기계기구 명',
            align: 'center',
            sortable: true,
          },
        ],
      },
      gridMmEquip: {
        columns: [
          {
            name: 'equipmentNo',
            field: 'equipmentNo',
            label: '설비 관리번호',
            align: 'center',
            sortable: true,
          },
          {
            name: 'equipMachineName',
            field: 'equipMachineName',
            label: '설비 명',
            align: 'center',
            sortable: true,
          },
        ],
      },
      editable: true,
      isSave: false,
      saveUrl: '',
      listMmUrl: '',
      insertMmUrl: '',
      deleteMmUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.mdm.sop.job.update.url;
      this.listMmUrl = selectConfig.mdm.sop.mm.list.url;
      this.insertMmUrl = transactionConfig.mdm.sop.mm.insert.url;
      this.deleteMmUrl = transactionConfig.mdm.sop.mm.delete.url;
      // code setting
      // list setting
    },
    saveSopInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.sop.regUserId = this.$store.getters.user.userId;
              this.sop.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail')
    },
    getMmMachines() {
      if (this.isOld) {
        this.$http.url = this.listMmUrl;
        this.$http.type = "GET";
        this.$http.param = { 
          mdmSopId: this.popupParam.mdmSopId, 
          equipMachineTypeCd: 'EMT0000002' 
        };
        this.$http.request((_result) => {
          this.sop.mmMachines = _result.data;
        });
      }
    },
    addRowMmMachine() {
      this.popupOptions.title = '기계/장비 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.sop.plantCd,
      };
      // this.popupOptions.target = () => import(`${'@/pages/sop/mim/hazardEquipment/hazardEquipmentClassPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopMmMachine;
    },
    closePopMmMachine(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, (item) => {
          if (this.$_.findIndex(this.sop.mmMachines, { equipMachineCd: item.hhmHazardousMachineryId }) === -1) {
            saveData.push({
              mdmSopMmCd: '',
              mdmSopId: this.popupParam.mdmSopId,
              equipMachineCd: item.hhmHazardousMachineryId,
              equipMachineTypeCd: 'EMT0000002',
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            });
          }
        });
            
        this.$http.url = this.insertMmUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getMmMachines();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    removeRowMmMachine() {
      let selectData = this.$refs['mmMachineTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteMmUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              this.getMmMachines();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
    getMmEquips() {
      if (this.isOld) {
        this.$http.url = this.listMmUrl;
        this.$http.type = "GET";
        this.$http.param = { 
          mdmSopId: this.popupParam.mdmSopId, 
          equipMachineTypeCd: 'EMT0000001' 
        };
        this.$http.request((_result) => {
          this.sop.mmEquips = _result.data;
        });
      }
    },
    addRowMmEquip() {
      this.popupOptions.title = '설비 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.sop.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopMmEquip;
    },
    closePopMmEquip(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, (item) => {
          if (this.$_.findIndex(this.sop.mmEquips, { equipMachineCd: item.equipmentCd }) === -1) {
            saveData.push({
              mdmSopMmCd: '',
              mdmSopId: this.popupParam.mdmSopId,
              equipMachineCd: item.equipmentCd,
              equipMachineTypeCd: 'EMT0000001',
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            });
          }
        });
            
        this.$http.url = this.insertMmUrl;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getMmEquips();
          window.getApp.$emit('APP_REQUEST_SUCCESS');
        });
      }
    },
    removeRowMmEquip() {
      let selectData = this.$refs['mmEquipTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteMmUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              this.getMmEquips();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
  }
};
</script>
<style lang="sass">
.margincard-0
  margin-top: -15px
</style>